.songContainer {
    display: flex;
    flex-direction: row;
    gap: 15px;
    position: relative;
}

.songImg {
    border-radius: 3px;
    height: 3.3rem;
    cursor: pointer;
    transform: scale(1);
    filter: brightness(100%);
    transition: 0.15s;
}
.songImg:hover {
    transform: scale(1.05);
    filter: brightness(70%);
    transition: 0.15s;
}
@media (hover: hover) {
    .songImg:hover {
        transform: scale(1.05);
        filter: brightness(70%);
        transition: 0.15s;
    }
}

.dot {
    color: white;
    position: absolute;
    transition: 0.35s ease-in-out;
}