.blahEmoji {
    position: absolute;
    font-size: 2rem;
    margin-top: -625px;
    /*margin-left: 0;*/
    opacity: 0;
}
.blahEmoji-anim1 {
    margin-left: -150px;
    animation: moveUpAndFadeLeft 0.45s ease-in-out forwards;
}
.blahEmoji-anim2 {
    margin-left: 0px;
    animation: moveUpAndFadeMiddle 0.45s ease-in-out forwards;
}
.blahEmoji-anim3 {
    margin-left: 150px;
    animation: moveUpAndFadeRight 0.45s ease-in-out forwards;
}

@keyframes moveUpAndFadeLeft {
    0% {
        margin-top: -650px;
        opacity: 0;
    }
    50% {
        opacity: 100%;
    }
    100% {
        margin-top: -675px;
        opacity: 0;
    }
}
@keyframes moveUpAndFadeMiddle {
    0% {
        margin-top: -650px;
        opacity: 0;
    }
    50% {
        opacity: 100%;
    }
    100% {
        margin-top: -675px;
        opacity: 0;
    }
}
@keyframes moveUpAndFadeRight {
    0% {
        margin-top: -650px;
        opacity: 0;
    }
    50% {
        opacity: 100%;
    }
    100% {
        margin-top: -675px;
        opacity: 0;
    }
}