.App {
  text-align: center;
  height: 100svh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: radial-gradient(black, #050505);
  user-select: none;
}

@keyframes fireFlicker {
  0% { background-color: #8b0000; }   /* Dark Red */
  10% { background-color: #ff4500; }  /* Orange */
  15% { background-color: #8b0000; }  /* Dark Red */
  20% { background-color: #ff6347; }  /* Tomato */
  25% { background-color: #8b0000; }  /* Dark Red */
  35% { background-color: #b22222; }  /* Firebrick */
  40% { background-color: #8b0000; }  /* Dark Red */
  50% { background-color: #ff4500; }  /* Orange */
  55% { background-color: #8b0000; }  /* Dark Red */
  60% { background-color: #ff6347; }  /* Tomato */
  65% { background-color: #8b0000; }  /* Dark Red */
  75% { background-color: #b22222; }  /* Firebrick */
  80% { background-color: #8b0000; }  /* Dark Red */
  90% { background-color: #ff4500; }  /* Orange */
  95% { background-color: #8b0000; }  /* Dark Red */
  100% { background-color: #b22222; } /* Firebrick */
}

.fire {
  opacity: 0.35;
  position: fixed;
  width: inherit;
  height: inherit;
  animation: fireFlicker 25s infinite;
  z-index: 0;
}

.stars, .flowers, .skulls {
  position: fixed;
  width: inherit;
  height: inherit;
  opacity: 0.125;
  background-size: 25%;
  background-repeat: repeat;
  animation: moveBottomLeft-TopRight 100s linear infinite;
}
.stars {
  background-image: url('img/patterns/stars.svg');
}
.flowers {
  background-image: url('img/patterns/flower.png');
}
.skulls {
  opacity: 0.175;
  background-size: 5%;
  background-image: url('img/patterns/skull.png');
  animation: moveLeft-Right 100s linear infinite;
}
.skulls::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 0, 0, 0.5); /* Overlay color and transparency */
  mix-blend-mode: multiply; /* Change blend mode as needed */
  pointer-events: none; /* Ensure overlay doesn't interfere with interactions */
  animation: rainbow 15s linear infinite
}

@keyframes moveBottomLeft-TopRight {
  from {background-position: bottom left;}
  to {background-position: top right;}
}
@keyframes moveLeft-Right {
  from {background-position: left;}
  to {background-position: right;}
}
@keyframes rainbow {
  0% {
    filter: hue-rotate(0deg);
  }
  50% {
    filter: hue-rotate(180deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}

body {
  margin: 0;
}

body {
  /*background: linear-gradient(-45deg, #dc4598, #c6d32d, #fdd4c0, #007dbf);*/
  /*background-size: 600% 600%;*/
  /*animation: gradient 15s ease infinite;*/
  /*height: 100svh;*/
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

#bloatwareText {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  z-index: 4;
}

.homeContainer {
  text-align: center;
  height: 100svh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 2;
}