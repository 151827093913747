.announcementText {
    text-align: center;
    display: flex;
    flex-direction: column;
    font-size: 2.1rem;
    color: white;
    line-height: 40px;
    font-family: "ConsolasBold", sans-serif;
    font-weight: bold;
    justify-content: center;
    align-items: center;
}

.announcementText div:first-child {
    margin-top: 15px;
}
.announcementText div:nth-child(2) {
    font-size: 2.0rem;
    font-style: italic;
    margin-left: -10px;
}

.btn {
    /*display: flex;*/
    width: calc(100% - 28px);
    font-family: "ConsolasBold", sans-serif;
    margin-top: 15px;
    /*border-radius: 15px;*/
    /*background-color: #836328;*/
    color: #ffd1c7;
    text-decoration: none;
    /*border: white 2px solid;*/
    border: white 7px solid;
    filter: brightness(100%);
    padding: 8px 8px 4px 8px;
}

.preSaveBtn {
    font-size: 2rem;
    background-size: cover;
    animation: brighten 3s ease-in-out infinite;
}

.musicVideoBtn {
    font-size: 2.2rem;
    color: #ffffff;
    border: #b75323 7px solid;
    /*background-color: #ff0000;*/
    transition: 0.15s;
}
.preSaveBtn:hover, .musicVideoBtn:hover {
    filter: brightness(50%);
    transition: 0.15s;
}

.socialContainer {
    margin-top: 30px;
}

.spotifyBtn {
    width: 512px;
    height: 512px;
}

.socialBtn {
    padding: 15px 30px 15px 30px;
    border: none;
    height: fit-content;
    width: fit-content;
}
.socialImg {
    height: 30px;
    filter: invert(1);
    transition: 0.15s;
}
.socialImg:hover {
    filter: invert(0.5);
    transition: 0.15s;
}

.bigInstaBtn {
    padding: 2px 0px 0px 0px;
    color: white;
    font-size: 1.8rem;
    border-image-slice: 10;
    border-image-source: linear-gradient(-45deg, #ff8f19, #882ade, #ff3c8b, #ae24e2);
}

@keyframes brighten {
    0% {
        filter: brightness(50%);
    }
    50% {
        filter: brightness(175%);
    }
    100% {
        filter: brightness(50%);
    }
}