@media screen and (max-device-width: 1500px) {
    .orbit {
        height: 700px;
    }

    .satelliteContainer {
        height: 760px;
    }

    .stars {
        opacity: 0.2;
    }
}