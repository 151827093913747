.textContainer {
    margin-top: 25px;
    padding: 15px;
    border: 9px white solid;
    border-image-slice: 10;
    border-image-source: linear-gradient(-45deg, #dc4598, #c6d32d, #fdd4c0, #007dbf);
    /*-webkit-border-image: -webkit-linear-gradient(-45deg, #dc4598, #c6d32d, #fdd4c0, #007dbf) 10;*/
    animation: border-gradient 5s linear infinite;
    /*-webkit-animation: border-gradient 5s linear infinite;*/
}

@keyframes border-gradient {
    0% {
        border-image-source: linear-gradient(-45deg, #dc4598, #c6d32d, #fdd4c0, #007dbf);
    }
    25% {
        border-image-source: linear-gradient(-45deg, #c6d32d, #fdd4c0, #007dbf, #dc4598);
    }
    50% {
        border-image-source: linear-gradient(-45deg, #007dbf, #dc4598, #c6d32d, #fdd4c0);
    }
    75% {
        border-image-source: linear-gradient(-45deg, #fdd4c0, #007dbf, #dc4598, #c6d32d);
    }
    100% {
        border-image-source: linear-gradient(-45deg, #dc4598, #c6d32d, #fdd4c0, #007dbf);
    }
}

@-webkit-keyframes border-gradient {
    0% {
        -webkit-border-image: -webkit-linear-gradient(-45deg, #dc4598, #c6d32d, #fdd4c0, #007dbf);
        -webkit-animation-timing-function: linear;
    }
    25% {
        -webkit-border-image: -webkit-linear-gradient(-45deg, #c6d32d, #fdd4c0, #007dbf, #dc4598);
        -webkit-animation-timing-function: linear;
    }
    50% {
        -webkit-border-image: -webkit-linear-gradient(-45deg, #007dbf, #dc4598, #c6d32d, #fdd4c0);
        -webkit-animation-timing-function: linear;
    }
    75% {
        -webkit-border-image: -webkit-linear-gradient(-45deg, #fdd4c0, #007dbf, #dc4598, #c6d32d);
        -webkit-animation-timing-function: linear;
    }
    100% {
        -webkit-border-image: -webkit-linear-gradient(-45deg, #dc4598, #c6d32d, #fdd4c0, #007dbf);
        -webkit-animation-timing-function: linear;
    }
}

.b-canvas {
}