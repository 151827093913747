.orbit {
    /*margin-bottom: 600px;*/
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    height: 50svw;
    /*min-height: 800px;*/
    font-size: 3rem;
    position: fixed;
    transform-origin: center;
    animation: spin 150s linear infinite;
    max-height: 750px;
}

.satelliteContainer {
    height: 53svw;
    animation: spin 50s linear infinite;
}

.satellite {
    z-index: 5;
    transform-origin: center;
    animation: spin 50s linear infinite;
}

.ring {
    width: inherit;
    height: inherit;
    position: fixed;
    /*border: 5px dashed rgba(117, 117, 117, 0.49);*/
    /*border-radius: 50%;*/
    /*background-image: linear-gradient(to right, #868686 33%, rgba(255,255,255,0) 0%);*/
    /*background-position: bottom;*/
    /*background-size: 3px 1px;*/
    /*background-repeat: repeat-x;*/
    filter: opacity(0.175);
    animation: spin 1000s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}